import { useLoaderData } from "react-router-dom";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  DataTableCell,
} from "@propra/react-pdf-table";
import { useEffect, useState } from "react";
import useAPIData from "../../../functions/useAPIData";

import logoVial from "../../../data_et_files/vial_mali_logo.png";

const formatCurrency = (number) => {
  // Add thousands separator
  const formattedNumber = number
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    .replace(".", ",");

  // Format the number as a currency string
  return `${formattedNumber}`;
};

export default function RapportsCaisse() {
  //Data & vars
  const entity = useLoaderData();

  const caisses = useAPIData("sourceFinanciere").filter((x) => x.type == "CA");

  const [thisTxn, setThisTxn] = useState(null);

  const [txnAfter, setTxnAfter] = useState(null);

  const [soldeDebut, setSoldeDebut] = useState(null);
  const [soldeFin, setSoldeFin] = useState(null);

  const [thisSource, setThisSource] = useState(null);

  const [formData, setFormData] = useState({
    source: 1,
  });

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData((prevValues) => ({ ...prevValues, [name]: value }));
  }

  function handleSubmit(event) {
    event.preventDefault();
    setThisTxn(
      entity.filter(
        (x) => x.date == formData.date && x.source == formData.source
      )
    );
    setThisSource(caisses && caisses.filter((x) => x.id == formData.source)[0]);
    setTxnAfter(
      entity.filter(
        (x) => x.date >= formData.date && x.source == formData.source
      )
    );
  }

  useEffect(() => {
    setSoldeDebut(
      thisSource &&
        txnAfter &&
        thisSource.soldeCourant -
          txnAfter.reduce(
            (a, { montant, type }) =>
              (type == "CR" ? Number(montant) : -Number(montant)) + Number(a),
            0
          )
    );
    setSoldeFin(
      thisSource &&
        txnAfter &&
        thisTxn &&
        thisSource.soldeCourant -
          txnAfter.reduce(
            (a, { montant, type }) =>
              (type == "CR" ? Number(montant) : -Number(montant)) + Number(a),
            0
          ) +
          thisTxn.reduce(
            (a, { montant, type }) =>
              (type == "CR" ? Number(montant) : -Number(montant)) + Number(a),
            0
          )
    );
  }, [thisTxn, thisSource]);

  //Document
  // Create styles
  const styles = StyleSheet.create({
    page: {
      backgroundColor: "white",
      paddingVertical: "30px",
      paddingHorizontal: "40px",
    },
    tableHeader: {
      fontWeight: "bold",
      textAlign: "center",
      fontSize: "14px",
      margin: "auto",
    },
    tableCellNumber: {
      textAlign: "center",
      fontSize: "11px",
    },
    tableCellText: {
      fontSize: "11px",
    },
  });

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          {/* <Image
            src={logoVial}
            style={{
              width: "100px",
              height: "50px",
              alignSelf: "left",
            }}
          /> */}
          <Text
            style={{
              textDecoration: "underline",
              fontWeight: "extrabold",
              marginBottom: "20px",
            }}
          >
            Rapport journalier pour {thisSource.nom} en date du {formData.date}
          </Text>

          <Table
            data={[
              {
                montant: soldeDebut,
                libelle: "",
                type: "SD",
              },
            ]
              .concat(
                thisTxn.sort((a, b) => {
                  const nameA = a.type;
                  const nameB = b.type;
                  if (a.date == b.date && nameA > nameB) {
                    return 1;
                  }
                  if (a.date == b.date && nameA < nameB) {
                    return -1;
                  }

                  return 0;
                })
              )
              .concat([
                {
                  montant: soldeFin,
                  libelle: "",
                  type: "SF",
                },
              ])}
          >
            <TableHeader>
              <TableCell weighting={0.6} style={styles.tableHeader}>
                Libellé
              </TableCell>
              <TableCell weighting={0.1} style={styles.tableHeader}>
                Solde début
              </TableCell>
              <TableCell weighting={0.1} style={styles.tableHeader}>
                Crédit
              </TableCell>
              <TableCell weighting={0.1} style={styles.tableHeader}>
                Debit
              </TableCell>
              <TableCell weighting={0.1} style={styles.tableHeader}>
                Solde &nbsp; fin
              </TableCell>
            </TableHeader>
            <TableBody>
              <DataTableCell
                weighting={0.6}
                style={styles.tableCellText}
                getContent={(r) => r.libelle}
              />
              <DataTableCell
                weighting={0.1}
                style={[styles.tableCellNumber, { color: "blue" }]}
                getContent={(r) => r.type == "SD" && r.montant}
              />
              <DataTableCell
                weighting={0.1}
                style={styles.tableCellNumber}
                getContent={(r) => r.type == "CR" && r.montant}
              />
              <DataTableCell
                weighting={0.1}
                style={styles.tableCellNumber}
                getContent={(r) => r.type == "DB" && r.montant}
              />
              <DataTableCell
                weighting={0.1}
                style={[styles.tableCellNumber, { color: "red" }]}
                getContent={(r) => r.type == "SF" && r.montant}
              />
            </TableBody>
          </Table>
        </View>
      </Page>
    </Document>
  );

  //return
  return (
    <div>
      <form method="post" onSubmit={handleSubmit}>
        <div class="w3-row-padding">
          <div class="w3-quarter">
            <label>Selectionner caisse</label> &nbsp;
            <select
              name="source"
              value={formData.source}
              onChange={handleChange}
              style={{ height: "40px", width: "300px" }}
              className="w3-sand"
              required
            >
              {caisses &&
                caisses.map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.nom}
                  </option>
                ))}
            </select>
          </div>

          <div class="w3-quarter">
            <label>Selectionner date du rapport</label> &nbsp;
            <input
              name="date"
              value={formData.date}
              onChange={handleChange}
              type="date"
              style={{ height: "40px", width: "300px" }}
              className="w3-sand w3-border"
              required
            />
          </div>

          <div>
            <button className="w3-button w3-red" style={{ width: "300px" }}>
              Générer rapport
            </button>
          </div>
        </div>
      </form>
      <hr />

      <div>
        {thisTxn && thisTxn.length > 0 ? (
          <PDFViewer style={{ width: "100%", minHeight: "100vh" }}>
            <MyDocument />
          </PDFViewer>
        ) : (
          <b className="w3-yellow w3-display-middle ">
            Pas de transaction pour le jour selectionné
          </b>
        )}
      </div>
    </div>
  );
}
