import { useEffect, useState } from "react";
import {
  Form,
  Link,
  NavLink,
  Outlet,
  redirect,
  useActionData,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import useAPIData from "../../../functions/useAPIData";
import sendTransactionFinFormData from "../../../functions/finances/sendTransactionFinFormData";
import FormTitle from "../../../components/FormTitle";
import { connect } from "react-redux";

function TransactionFinForm({ user }) {
  const entity = useLoaderData();

  let initValue = {};

  const [formData, setFormData] = useState(initValue);

  const destinations = useAPIData("recetteDepense");

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData((prevValues) => ({ ...prevValues, [name]: value }));
  }

  function handleSubmit(event) {
    event.preventDefault();

    sendTransactionFinFormData(
      {
        effectueePar: user && user.employeNumber,
        ...formData,
      },
      process.env.REACT_APP_THE_HOST + "/api/transactionFinanciere/",
      "POST",
      0
    );
  }
  return (
    <div className="repertoire-employe-layout">
      <div style={{ width: "800px" }}>
        <form method="post" onSubmit={handleSubmit}>
          <div>
            <FormTitle
              title={"Nouvelle transaction financière (DÉBIT/ CRÉDIT)"}
            />
          </div>

          <div className="w3-panel w3-light-gray">
            <h3>
              <p>
                Veuillez fournir les infos ci-dessous{" "}
                <span style={{ color: "red" }}>(requis)</span>
              </p>
            </h3>
          </div>
          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>
                <span style={{ color: "red" }}>Date</span>
              </b>
            </div>
            <div className="w3-threequarter">
              <input
                className="w3-input w3-border w3-sand"
                name="date"
                type="date"
                value={formData.date}
                onChange={handleChange}
                required={true}
              />
            </div>
          </div>
          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>
                <span style={{ color: "red" }}>Type de transaction</span>
              </b>
            </div>
            <div className="w3-threequarter w3-container">
              <select
                name="type"
                value={formData.type}
                onChange={handleChange}
                required={true}
                style={{ width: "100%", marginTop: "7.5px" }}
                className="w3-yellow"
              >
                <option value={""}>sélectionner</option>
                <option value="DB">Débit</option>
                <option value="CR">Crédit</option>
                {/* <option value="AD">Ajustement débit</option>
                <option value="AC">Ajustement crédit</option> */}
              </select>
            </div>
          </div>

          {/* <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>
                <span style={{ color: "red" }}>Facture à payer</span>
              </b>
            </div>
            <div className="w3-threequarter w3-container">
              <select
                name="destination"
                value={formData.destination}
                onChange={handleChange}
                style={{ width: "100%", marginTop: "7.5px" }}
                className="w3-yellow"
              >
                <option value={""}>NON-APPLICABLE</option>
                {destinations &&
                  destinations.map((x) => (
                    <option key={x.id} value={x.id}>
                      {x.numero}
                    </option>
                  ))}
              </select>
            </div>
          </div> */}

          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>
                <span style={{ color: "red" }}>Source/ Destination</span>
              </b>
            </div>
            <div className="w3-threequarter w3-container">
              <select
                name="source"
                value={formData.source}
                onChange={handleChange}
                required={true}
                style={{ width: "100%", marginTop: "7.5px" }}
                className="w3-yellow"
              >
                <option value={""}>sélectionner</option>
                {entity.map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.nom + "/ " + x.numeroCompte}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>
                <span style={{ color: "red" }}>Libellé</span>
              </b>
            </div>
            <div className="w3-threequarter">
              <input
                className="w3-input w3-border w3-sand"
                name="libelle"
                type="text"
                value={formData.libelle}
                onChange={handleChange}
                required={true}
              />
            </div>
          </div>
          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>
                <span style={{ color: "red" }}>Montant</span>
              </b>
            </div>
            <div className="w3-threequarter">
              <input
                className="w3-input w3-border w3-sand"
                name="montant"
                type="number"
                value={formData.montant}
                onChange={handleChange}
                required={true}
              />
            </div>
          </div>

          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>
                <span style={{ color: "red" }}>Méthode de paiement</span>
              </b>
            </div>
            <div className="w3-threequarter w3-container">
              <select
                name="methode"
                value={formData.methode}
                onChange={handleChange}
                required={true}
                style={{ width: "100%", marginTop: "7.5px" }}
                className="w3-yellow"
              >
                <option value={""}>sélectionner</option>
                <option value={"E"}>Espèce</option>
                <option value={"C"}>Chèque</option>
                <option value={"O"}>Orange monnaie</option>
              </select>
            </div>
          </div>

          <div className="w3-row w3-section">
            <div
              className="w3-quarter w3-button"
              style={{ pointerEvents: "none" }}
            >
              <b>Observations</b>
            </div>
            <div className="w3-threequarter">
              <textarea
                className="w3-input w3-border w3-sand"
                name="observations"
                type="text"
                value={formData.observations}
                onChange={handleChange}
              />
            </div>
          </div>

          <button
            className="w3-btn w3-ripple w3-green w3-round"
            style={{ width: "500px" }}
            name="action"
            type="submit"
          >
            Soumettre
          </button>
          <button
            className="w3-btn w3-ripple w3-light-grey w3-round w3-margin-left"
            style={{ width: "284px" }}
            onClick={() =>
              window.location.replace(
                process.env.REACT_APP_THE_HOST + "/ventes/chantiers"
              )
            }
          >
            Annuler
          </button>
        </form>

        <p></p>

        <p></p>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(TransactionFinForm);
