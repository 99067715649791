import { useEffect, useState, useMemo, useRef, useCallback } from "react";
import {
  Link,
  NavLink,
  Outlet,
  useLoaderData,
  useNavigate,
} from "react-router-dom";
import { useReactToPrint } from "react-to-print";
export default function TransactionFinListe() {
  const entity = useLoaderData(); //Articles

  const [listeEntity, setListeEntity] = useState(entity);

  const navigate = useNavigate();

  function selectionEntity(id) {
    navigate("" + id);
  }

  const toPrint = useRef(null);

  const handlePrint = useReactToPrint({
    documentTitle: "Historique des transactions",
    removeAfterPrint: true,
  });

  function search(event) {
    const searchKey = event.target.value.toLowerCase();
    if (searchKey)
      setListeEntity(
        entity.filter((entity) => {
          return (
            entity.sourceNom.toLowerCase().includes(searchKey) ||
            entity.libelle.toLowerCase().includes(searchKey) ||
            entity.destinationNom.toLowerCase().includes(searchKey)
          );
        })
      );
    else setListeEntity(entity);
  }

  return (
    <div className="repertoire-employe-layout">
      <div className="the-employe-table w3-cell" style={{ width: "100%" }}>
        <button
          onClick={() => {
            handlePrint(null, () => toPrint.current);
          }}
          className="w3-btn w3-ripple w3-pale-blue w3-round w3-margin-bottom"
          style={{ width: "300px" }}
        >
          <i className="fa fa-print" aria-hidden="true"></i> Imprimer
        </button>
        <input
          onChange={search}
          placeholder={"Entrez le libellé, la source ou la destination"}
          style={{ width: "100%", backgroundColor: "azure", height: "40px" }}
          autoFocus
        />
        <p></p>
        <div className="toPrint" ref={toPrint}>
          <div className="w3-card-4 w3-bar">
            <table className="w3-table-all w3-hoverable">
              <thead>
                <tr className="w3-light-grey">
                  <th>Transaction No.</th>
                  <th>Date</th>
                  <th>Libellé</th>
                  <th>Facture No.</th>
                  <th>Type de transaction</th>
                  <th>Montant</th>
                  <th>Source/ Destination</th>
                  <th>No. du compte</th>
                  <th>Methode de paiement</th>
                  <th>Observations</th>
                </tr>
              </thead>
              <tbody>
                {listeEntity
                  .sort((a, b) => {
                    const nameA = a.id; // ignore upper and lowercase
                    const nameB = b.id; // ignore upper and lowercase
                    if (nameA < nameB) {
                      return 1;
                    }
                    if (nameA > nameB) {
                      return -1;
                    }

                    // names must be equal
                    return 0;
                  })
                  .map((entity) => {
                    return (
                      <tr
                        key={entity.id}
                        // style={{ cursor: "pointer" }}
                        // onClick={() => selectionEntity(entity.id)}
                      >
                        <td>{entity.id}</td>
                        <td>{entity.date}</td>
                        <td>{entity.libelle}</td>
                        <td>
                          {entity.destination
                            ? entity.destinationNom.replace("RD", "")
                            : "NON APPLICABLE"}
                        </td>
                        <td>
                          {entity.type == "CR"
                            ? "Crédit"
                            : entity.type == "DB"
                            ? "Débit"
                            : entity.type == "TR"
                            ? "Transfert"
                            : "Autre"}
                        </td>
                        <td>{entity.montant}</td>
                        <td>
                          {entity.destinationSourceNom
                            ? entity.sourceNom +
                              "/ " +
                              entity.destinationSourceNom
                            : entity.sourceNom}
                        </td>
                        <td>{entity.sourceCompte}</td>
                        <td>
                          {entity.methode == "E"
                            ? "Espece"
                            : entity.methode == "C"
                            ? "Cheque"
                            : entity.methode == "O"
                            ? "Orange monnaie"
                            : "Autre"}
                        </td>
                        <td>{entity.observations}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div
        className="the-employe-details w3-container w3-cell"
        style={{ width: "1000px" }}
      >
        <Outlet />
      </div>
    </div>
  );
}
