import { NavLink, Outlet } from "react-router-dom";

export default function TransactionFinLayout() {
  return (
    <div className="repertoire-employes-layout">
      <div className="w3-pannel w3-gray">
        <div className="w3-bar">
          <h3 className="w3-bar-item w3-text-white">
            <strong>
              Transactions financières{" "}
              <i class="fa fa-arrows" aria-hidden="true" />
            </strong>
          </h3>
          <NavLink
            to=""
            id="theNavLinkMenu"
            className="w3-bar-item w3-button w3-ripple w3-yellow w3-round w3-margin-left w3-margin-top"
          >
            <i className="fa fa-book" aria-hidden="true" /> Historique des
            transactions
          </NavLink>

          <NavLink
            to="nouvelle-transaction-fin"
            id="theNavLinkMenu"
            className="w3-bar-item w3-button w3-ripple w3-indigo w3-round w3-margin-left w3-margin-top"
          >
            <i className="fa fa-plus-circle" aria-hidden="true" /> Nouvelle
            transaction (débit/ crédit)
          </NavLink>

          <NavLink
            // to="nouvelle-transaction-fin-transf"
            id="theNavLinkMenu"
            className="w3-bar-item w3-button w3-ripple w3-purple w3-round w3-margin-left w3-margin-top"
          >
            <i className="fa fa-plus-circle" aria-hidden="true" /> Nouvelle
            transaction (transfert)
          </NavLink>

          <NavLink
            to="/finances"
            id="theNavLinkMenu"
            className="w3-bar-item w3-button w3-ripple w3-light-gray w3-round w3-margin-left w3-margin-top"
          >
            <i className="fa fa-chevron-circle-left" aria-hidden="true" />{" "}
            Retour au menu Finances
          </NavLink>
        </div>
      </div>
      <div className="w3-container w3-padding">
        <div className="the-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
